import React from "react";
import {
  Box,
  Heading,
  Image
} from "grommet";
import payment_success from "../images/payment_success.png";

class PaySuccess extends React.Component {

  render() {
    return (
      <Box  align="center" background="white">
        <Box
          width="large"
          alignSelf="center"
          align="center"
          direction="column"
          pad={this.props?.size}
          margin={"medium"}
          style={{ margin: 100 }}
        >
          <Box width="xsmall">
            <Image src={payment_success} />
          </Box>
          <Heading level="3">Souscription réussi !</Heading>          
        </Box>
      </Box>
    );
  }
}

export default PaySuccess;
